import React from 'react';
import ReactDOM from 'react-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './index.css';

class Project extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <form>
            <h2>What's your budget and timeline?</h2>
            <label for="budget" >Budget: </label>
            <input type="number" name="budget" onChange={(e) => this.props.handleChange(e)} value={this.props.budget} />
            <label htmlFor="currency">Currency: </label>
                <select name="currency" onChange={(e) => this.props.handleChange(e)} value={this.props.currency} >
                    <option value='€'>€</option>
                    <option value="$">$</option>
                    <option value="£">£</option>
                    <option value="Ft">Ft</option>
                </select>
                <br/>
                <label>Timeline: </label>
                    <DatePicker name='start'
                        placeholderText='Start'
                        selected={this.props.start}
                        onChange={event => this.props.setStart(event)}
                        selectsStart
                        startDate={this.props.start}
                        endDate={this.props.end}
                    />
                    <DatePicker name='end'
                        placeholderText='End'
                        selected={this.props.end}
                        onChange={(event) => this.props.setEnd(event)}
                        selectsEnd
                        startDate={this.props.start}
                        endDate={this.props.end}
                        minDate={this.props.start}
                    />
                <br/>
            </form>
        );
    }
}

class Progress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {amount: 0};
    }
    render() {
            return <div>
            <h2>Current progress</h2>
                <progress value={this.state.amount} max={this.props.budget}></progress>
                <br/>
                {this.state.amount.toFixed(2)} {this.props.currency} has been spent on your project
            </div>
    }
    tick() {
        const time = new Date();
        const curtimestamp = time.valueOf();
        const starttimestamp = (new Date(this.props.start)).setHours(0, 0, 0, 0).valueOf();
        const endtimestamp = (new Date(this.props.end)).setHours(23, 59, 59, 999).valueOf();

        this.setState({
            amount: this.props.budget * (curtimestamp - starttimestamp) / (endtimestamp - starttimestamp),
            currency: this.state.currency,
            finished: false,
        });
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            100
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }
}

class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            budget: 1000,
            currency: '€',
            start: new Date(),
            end: new Date()
        };
        // This binding is necessary to make `this` work in the callback
        this.handleChange = this.handleChange.bind(this);
        this.setStart = this.setStart.bind(this);
        this.setEnd = this.setEnd.bind(this);

    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    setStart(date) {
        this.setState({
            start: date
        });
    }
    setEnd(date) {
        this.setState({
            end: date
        });
    }
    render() {
        return <div class="calculator">
            <div className='section title'>
                <h1>Project progress calculator</h1>
            </div>
            <div className='section input'>
                <Project budget={this.state.budget} currency={this.state.currency} start={this.state.start} end={this.state.end} handleChange={this.handleChange} setStart={this.setStart} setEnd={this.setEnd}/>
            </div>
            <div className='highlighted section'>
                <Progress budget={this.state.budget} currency={this.state.currency} start={this.state.start} end={this.state.end} />
            </div>
            <div className='footer section'>
                * No real money transfer was made
            </div>
        </div>;
    }
}

ReactDOM.render(<Calculator/>, document.getElementById('root'));